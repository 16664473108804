@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('fonts/style.css');
@tailwind base;

@layer base {
  html {
    font-family: "Berlingske Typewriter";
  }

  p {
    font-family: "Berlingske Typewriter";
  }

  h1 {
    font-size: 48px;
    line-height: 48px;
    font-weight: bold;
  }

  h2 {
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;
    color: black;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 40px;
      line-height: 42px;
      font-weight: bold;
    }

    h2 {
      font-size: 26px;
      line-height: 30px;
      font-weight: bold;
      color: black;
    }

    h3 {
      font-size: 20px;
      line-height: 26px;
      font-weight: bold;
    }
  }
}

@tailwind components;

@layer components {
  .max-w-o2o {
    @apply mx-auto;
  }
}

@tailwind utilities;

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
}

.slide {
  width: 50%;
}

#prevBtn,
#nextBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
}

#prevBtn {
  left: 0;
}

#nextBtn {
  right: 0;
}


.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 500,
    'GRAD' 0,
    'opsz' 24
}

input {
  outline: none;
}



.loader {
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-message {
  animation: slideIn 0.5s ease-out;
}